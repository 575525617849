import { Card, Container, Grid, Typography } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import WidgetCard from "./dashComponents/WidgetCard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SellIcon from "@mui/icons-material/Sell";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { getAllUsers } from "../../services/users";
import SuspenseFallback from "../../components/SuspenseFallback";
import { getAllAppointments } from "../../services/appointment";
import {getAllProducts, getAllProductSales} from "../../services/products";
import { getSalesReport } from "../../services/reports";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TopSalesTable from "./dashComponents/TopSalesTable";
import {Box} from "@mui/system";

export const Dashboard = () => {
  const [users, setUsers] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [products, setProducts] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [productSalesData, setProductSalesData] = useState(null);

  useEffect(() => {
    getAllProductTop10Sales();
    getUsers();
    getAppointments();
    getProducts();
    getSalesData();

  }, []);

  const getUsers = () => {
    getAllUsers().then((res) => {
      if (res && res.data) {
        setUsers(res.data);
      }
    });
  };

  const getAppointments = () => {
    console.log("appointments");
    getAllAppointments().then((res) => {
      console.log(res);
      if (res && res.data) {
        console.log(res.data.object);
        console.log(res.data.object.filter((x) => x.status === "Pending"));
        setAppointments(res.data.object.filter((x) => x.status === "Pending"));
      }
    });
  };

  const getAllProductTop10Sales = () => {
    console.log("product sales");
    getAllProductSales().then((res) => {
      console.log(res.data);
      if (res && res.data) {
        setProductSalesData(res.data);
        console.log(productSalesData)
      }
    });
  };

  const getProducts = () => {
    getAllProducts().then((res) => {
      if (res && res.data) {
        setProducts(res.data.object);
      }
    });
  };

  const getSalesData = () => {
    getSalesReport().then((res) => {
      if (res && res.data) {
        setSalesData(res.data);
      }
    });
  };

  if (users && appointments && products && salesData) {
    return (
      <Container maxWidth="100%">
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title="Users"
              icon={<PersonIcon fontSize="large" />}
              content={users.length}
              color={"orange"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title="Reservations"
              icon={<CalendarMonthIcon fontSize="large" />}
              content={appointments.length}
              color={"orange"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title="Products"
              icon={<SellIcon fontSize="large" />}
              content={products.length}
              color={"orange"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {salesData.weeklySales && (
            <Grid item xs={12} md={4}>
              <WidgetCard
                title="Weekly Sales"
                icon={<AssessmentIcon fontSize="large" />}
                content={`₱${salesData.weeklySales.toFixed(2)}`}
                color={"orange"}
              />
            </Grid>
          )}
          {salesData.monthlySales && (
            <Grid item xs={12} md={4}>
              <WidgetCard
                title="Monthly Sales"
                icon={<AssessmentIcon fontSize="large" />}
                content={`₱${salesData.monthlySales.toFixed(2)}`}
                color={"orange"}
              />
            </Grid>
          )}
          {salesData.yearlySales && (
            <Grid item xs={12} md={4}>
              <WidgetCard
                title="Yearly Sales"
                icon={<AssessmentIcon fontSize="large" />}
                content={`₱${salesData.yearlySales.toFixed(2)}`}
                color={"orange"}
              />
            </Grid>
          )}
        </Grid>
        <Box>
          <Grid container spacing={2}>
            {/* Weekly Sales */}
            <Grid item xs={12} md={4}>
              <TopSalesTable
                  currentPeriod={"weekly"}
                  salesData={productSalesData.weekly}
              />
            </Grid>

            {/* Monthly Sales */}
            <Grid item xs={12} md={4}>
              <TopSalesTable
                  currentPeriod={"monthly"}
                  salesData={productSalesData.monthly}
              />
            </Grid>

            {/* Yearly Sales */}
            <Grid item xs={12} md={4}>
              <TopSalesTable
                  currentPeriod={"yearly"}
                  salesData={productSalesData.yearly}
              />
            </Grid>
          </Grid>
        </Box>

        <Card sx={{ padding: "20px", mt: 3 }}>
          {/* <Typography variant="h4" sx={{ mb: 2 }} align="center">
            Calendar
          </Typography> */}
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            height="90vh"
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

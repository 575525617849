import http from "./http";

export function getAllProducts() {
  return http.get("/products/all");
}

export function getAllProductsNoFilter() {
  return http.get("/products/all-no-filter");
}
export function addProduct(data) {
  return http.post("/products/add", data);
}

export function editProduct(id, data) {
  console.log(data)
  return http.patch(`/products/edit/${id}`, data);
}

export function getProductbyID(id) {
  return http.get(`/products/item/${id}`);
}

export function deleteProduct(id) {
  return http.delete(`/products/delete/${id}`);
}


export function addProductStocks(stockData) {
  return http.post(`/products/add/stock`, stockData);
}

export function getAllProductSales() {
  return http.get("/report/generate/topsales");
}

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

const TopSalesTable = ({ salesData, currentPeriod }) => {
    console.log(salesData);

    const columns = [
        {
            field: "productname",
            headerName: "Product Name",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "totalsales",
            headerName: "Sales",
            width: 100,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "price",
            headerName: "Price",
            width: 150,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => `₱${params.value.toFixed(2)}`,
        },
    ];

    const rows = salesData?.map((item, index) => ({
        id: index + 1,
        ...item,
    })) || [];

    return (
        <Box>
            <Typography variant="h6" sx={{ marginBottom: 2, textAlign: "center" }}>
                Top Sales {currentPeriod ? `(${currentPeriod.charAt(0).toUpperCase() + currentPeriod.slice(1)})` : ""}
            </Typography>
            <Box sx={{ height:"100%", width: "100%" }}>
                {rows.length > 0 ? (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10]}
                        disableSelectionOnClick
                        sx={{ textAlign: "center" }}
                    />
                ) : (
                    <Typography variant="body1" align="center">
                        No data available for {currentPeriod}.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default TopSalesTable;
